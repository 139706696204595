import React from "react"
import { Layout } from "@shopify/polaris"

import { LogsList } from "./LogsList"
import { LogsPagination } from "./LogsPagination"

export const Logs = props => {
  const { items, showPrev, showNext, onPagination, loading } = props

  return (
    <Layout>
      <Layout.Section>
        <LogsList {...props} />
        <br />
        <LogsPagination
          showPrev={showPrev && !loading && items.length}
          showNext={showNext && !loading && items.length}
          onPagination={onPagination}
        />
      </Layout.Section>
    </Layout>
  )
}
