import React from "react"
import { Filters } from "@shopify/polaris"

export const LogsFilter = props => {
  const { queryValue, onQueryChange } = props

  return (
    <Filters
      filters={[]}
      focused={true}
      queryValue={queryValue}
      onQueryChange={onQueryChange}
      onQueryClear={() => onQueryChange("")}
    />
  )
}
