import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page, Modal } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { genericNavBar } from "../helpers/navigation"

import { Logs } from "components/Logs/Logs"

const LogsPage = props => {
  const { firebase, shop, location } = props

  const pageSize = 10
  const searchParams = new URLSearchParams(window.location.search)

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState(false)
  const [initialDoc, setInitialDoc] = useState(false)
  const [firstDoc, setFirstDoc] = useState(searchParams.get("first") ? atob(searchParams.get("first")) : false)
  const [lastDoc, setLastDoc] = useState(searchParams.get("last") ? atob(searchParams.get("last")) : false)
  const [showPrev, setPrev] = useState(false)
  const [showNext, setNext] = useState(true)
  const [queryValue, setQueryValue] = useState(searchParams.get("search") || false)
  const [sortField, setSortField] = useState(searchParams.get("sort") || "createdAt")
  const [sortDirection, setSortDirection] = useState(searchParams.get("direction") || "desc")
  const [selectedItems, setSelectedItems] = useState([])

  const handleData = (snapshot, initial = false) => {
    const length = snapshot.docs.length
    setItems(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    if (length) {
      if (initial) setInitialDoc(snapshot.docs[0].id)
      setFirstDoc(snapshot.docs[0].data().createdAt)
      setLastDoc(snapshot.docs[length - 1].data().createdAt)
      setPrev(!initial && initialDoc !== snapshot.docs[0].id)
      setNext(length === pageSize)
    }
    setLoading(false)
  }

  const handleQuery = (prev = false, next = false) => {
    setLoading(true)
    setSelectedItems([])
    ReactifyApp.Firebase.queryFirestore(
      firebase,
      `shops/${shop}/logs`,
      prev ? firstDoc : false,
      next ? lastDoc : false,
      handleData,
      queryValue,
      [sortField, sortDirection],
      pageSize
    )
  }

  useEffect(() => {
    handleQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, sortDirection])

  useEffect(() => {
    searchParams.set("sort", sortField)
    searchParams.set("direction", sortDirection)
    if (firstDoc) searchParams.set("first", btoa(firstDoc))
    if (lastDoc) searchParams.set("last", btoa(lastDoc))

    if (queryValue) {
      searchParams.set("search", queryValue)
    } else {
      searchParams.delete("search")
    }

    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, sortDirection, firstDoc])

  const handleQueryChange = value => {
    setQueryValue(value)
    setSelectedItems([])
  }

  const handleOrderChange = async value => {
    const order = value.split(":")
    await setSortField(order[0])
    await setSortDirection(order[1])
    await setSelectedItems([])
  }

  const handleDeleteAction = async items => {
    await ReactifyApp.Firebase.firestoreBatchDelete(firebase, `shops/${shop}/logs`, items)
    setSelectedItems([])
    handleQuery()
  }

  const handleRetryAction = async task => {
    const logId = await firebase
      .firestore()
      .collection(`shops/${shop}/tasks`)
      .doc(`${task.replace()}`)
      .get()
      .then(doc => doc.data()?.logId)

    await firebase
      .firestore()
      .collection(`shops/${shop}/logs`)
      .doc(`${logId}`)
      .set({ status: "info", progress: "partiallyComplete", action: false, data: false }, { merge: true })

    if (task === "download") {
      await firebase
        .firestore()
        .collection(`shops/${shop}/tasks`)
        .doc("download")
        .delete()
      await firebase
        .firestore()
        .collection(`shops/${shop}/tasks`)
        .doc("download")
        .set({ logId, type: "collection", firstRun: true, retry: true }, { merge: false })
    } else {
      await firebase
        .firestore()
        .collection(`shops/${shop}/tasks`)
        .doc(`${task}`)
        .set({ retry: true }, { merge: true })
    }

    handleQuery()
  }

  const config = {
    ...genericNavBar,
    title: "Logs",
  }

  return (
    <Page title={config.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...config} />
      <Logs
        items={items}
        sortValue={`${sortField}:${sortDirection}`}
        sortOptions={[
          { label: "Newest first", value: "createdAt:desc" },
          { label: "Oldest first", value: "createdAt:asc" },
        ]}
        onSortChange={handleOrderChange}
        queryValue={queryValue}
        onQueryChange={handleQueryChange}
        onDeleteAction={handleDeleteAction}
        selectedItems={selectedItems}
        onSelectedChange={setSelectedItems}
        onModelOpen={(title, message, data, format) =>
          setModal({
            title,
            message,
            data,
            format,
            size: "Large",
            open: true,
          })
        }
        onRetryAction={handleRetryAction}
        showPrev={showPrev}
        showNext={showNext}
        onPagination={handleQuery}
        loading={loading}
      />
      <Modal
        title={`View ${modal.format ? modal.format : "content"}`}
        open={modal.open}
        onClose={() =>
          setModal({
            open: false,
          })
        }
      ></Modal>
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(LogsPage)
