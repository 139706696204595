import React from "react"
import { navigate } from "gatsby"
import { Avatar, ResourceItem, TextStyle, Badge } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

export const LogsItem = props => {
  const { item, onModelOpen, condensed } = props

  const media = <Avatar customer size="medium" name={item?.title} source={ReactifyApp.Utils.platformIcons[item?.platform]} />

  const actions = item.action
    ? [
        {
          content: item.action.label,
          accessibilityLabel: item.action.label,
          onAction: () => actionType(item.action.type),
        },
      ]
    : null

  const actionType = type => {
    if (type === "link") return navigate(item.action.link)
    if (type === "content") return onModelOpen(item.action.label, item.message, false, null)
    if (type === "data") return onModelOpen(item.action.label, false, item.data, item.dataFormat)
  }

  let status = item?.status.replace("complete", "success")
  status = item?.status.replace("error", "critical")

  return (
    <ResourceItem id={item?.id} media={media} shortcutActions={actions} verticalAlignment="center">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: condensed ? "65%" : "70%",
          }}
        >
          <h3>
            <TextStyle variation="strong">{item?.title}</TextStyle>
          </h3>
          <div>{item.message}</div>
        </div>
        <div
          style={{
            width: condensed ? "25%" : "15%",
            display: "flex",
          }}
        >
          {ReactifyApp.Utils.dateDistance(new Date(item?.createdAt?.seconds ? item.createdAt.seconds * 1000 : item.createdAt))}
        </div>
        <div
          style={{
            width: condensed ? "10%" : "15%",
            display: "flex",
            justifyContent: "flex-end",
            textTransform: "capitalize",
          }}
        >
          <Badge size="medium" status={status}>
            {item?.type}
          </Badge>
        </div>
      </div>
    </ResourceItem>
  )
}
