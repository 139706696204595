import React from "react"
import { Card, ResourceList } from "@shopify/polaris"

import { LogsItem } from "./LogsItem"
import { LogsFilter } from "./LogsFilter"

export const LogsList = props => {
  const { items, sortValue, sortOptions, onSortChange, queryValue, onQueryChange, onRetryAction, onModelOpen, loading } = props

  const resourceName = {
    singular: "log",
    plural: "logs",
  }

  const renderItem = item => <LogsItem item={item} onModelOpen={onModelOpen} onRetryAction={onRetryAction} />

  const filterControl = <LogsFilter queryValue={queryValue} onQueryChange={onQueryChange} />

  return (
    <Card>
      <ResourceList
        items={items}
        resourceName={resourceName}
        renderItem={renderItem}
        sortValue={sortValue}
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        filterControl={filterControl}
        // promotedBulkActions={bulkActions}
        // selectedItems={selectedItems}
        // onSelectionChange={onSelectedChange}
        // selectable={true}
        loading={loading}
      />
    </Card>
  )
}
